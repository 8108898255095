<template>
  <Form v-slot="{ invalid, changed }" class="mailing-template-form">
    <div class="grid-2 mb-32">
      <FormItem rules="required" v-slot="{ isError }" class="grid-2__full-item">
        <Select
          v-model="form.to"
          :options="toOptions"
          label="To"
          placeholder="To"
          is-multi
          is-custom-input
          :is-disabled="isDisabled"
          :is-error="isError"
        />
      </FormItem>
      <FormItem rules="required" v-slot="{ isError }">
        <Select
          v-model="form.event"
          :options="eventOptions"
          label="Event"
          placeholder="Event"
          :is-error="isError"
          :is-disabled="isDisabled"
        />
      </FormItem>
      <FormItem rules="required" v-slot="{ isError }">
        <Input
          v-model="form.subject"
          label="Subject"
          placeholder="Subject"
          :is-error="isError"
          :is-disabled="isDisabled"
        />
      </FormItem>
      <Input
        :value="fromDisplayName"
        label="From"
        placeholder="From"
        :is-disabled="isDisabled"
        :isReadonly="true"
      />
      <FormItem>
        <Select
          v-model="form.replyTo"
          :options="toOptions"
          label="Reply To"
          :placeholder="`${venue.email}`"
          is-multi
          is-custom-input
          :is-disabled="isDisabled"
          is-clearable
        />
      </FormItem>
      <FormItem>
        <Select
          v-model="form.cc"
          :options="ccOptions"
          label="CC"
          placeholder="CC"
          is-multi
          is-custom-input
          :is-disabled="isDisabled"
        />
      </FormItem>
      <FormItem>
        <Select
          v-model="form.bcc"
          :options="ccOptions"
          label="BCC"
          placeholder="BCC"
          is-multi
          is-custom-input
          :is-disabled="isDisabled"
        />
      </FormItem>
      <FormItem v-slot="{ isError }" class="grid-2__full-item">
        <Input
          v-model="form.description"
          label="Description"
          placeholder="Description"
          is-textarea
          :is-error="isError"
          :is-disabled="isDisabled"
        />
      </FormItem>
      <FormItem
        rules="required"
        class="grid-2__full-item mailing-template-form__text"
      >
        <div class="grid-2__full-item mailing-template-form__text-top">
          <label for="text" class="mailing-template-form__label">Text</label>
          <div class="mailing-template-form__text-top__right">
            <Switcher v-model="isEditTextMode" label="Edit mode" />
            <Button
              variant="secondary"
              is-outlined
              :is-disabled="!form.subject || !form.text"
              :is-loading="isPreviewLoading"
              @click="handleShowPreview"
              class="mailing-template-form__previewButton"
            >
              <Icon name="eye" :size="25" color="black" />
              Preview
            </Button>
          </div>
        </div>

        <MailingTemplateEditor
          v-show="isEditTextMode"
          v-model="form.text"
          :isReadonly="isDisabled"
        />
        <iframe
          v-if="!isEditTextMode && form.text"
          :srcdoc="sanitize(form.text)"
          class="mailing-template-form__text__preview"
        />
      </FormItem>
    </div>
    <div class="mailing-template-form__actions">
      <Button
        v-if="!isDisabled"
        :is-disabled="invalid || !changed || isPreviewLoading"
        :is-loading="isSubmitting"
        @click="submit"
      >
        Save
      </Button>
      <Guard permission="mailingTemplates.delete" v-slot="{ isAvailable }">
        <Button
          v-if="isAvailable && isEditing && !value.isDefault"
          is-outlined
          variant="danger"
          :is-disabled="isSubmitting || isPreviewLoading"
          :is-loading="isDeleting"
          @click="handleDelete"
        >
          Delete
        </Button>
      </Guard>
      <Button
        v-if="!isDisabled && value.isDefault"
        :is-disabled="isSubmitting || isPreviewLoading"
        :is-loading="isRestoring"
        variant="secondary"
        is-outlined
        @click="restore"
      >
        Restore to default
      </Button>
    </div>
    <MailPreviewModal
      :modal-name="previewModalName"
      :previews="previews"
      :text="form.text"
      :subject="form.subject"
    />
  </Form>
</template>

<script>
import dialog from "@/plugins/dialog";
import { mapActions, mapState } from "vuex";
import Guard from "@/components/common/Guard";
import { MailingTemplateToEnum } from "@/helpers/enums";
import { templateEventTypes } from "@/helpers/const";
import { roleOptions } from "@/helpers/mocks";
import MailPreviewModal from "@/components/mailing-templates/MailPreviewModal.vue";
import MailingTemplatesService from "@/api/services/MailingTemplatesService";
import MailingTemplateEditor from "@/components/mailing-templates/MailingTemplateEditor.vue";
import alert from "@/plugins/alert";
import { sanitize } from "@/helpers/utils";

export default {
  name: "MailingTemplateForm",
  components: {
    MailingTemplateEditor,
    MailPreviewModal,
    Guard,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        ...this.value,
      },
      isDeleting: false,
      eventOptions: templateEventTypes,
      ccOptions: [...roleOptions],
      toOptions: [
        { value: MailingTemplateToEnum.CUSTOMER, name: "Customer" },
        ...roleOptions,
      ],
      previewModalName: "mailPreviewModal",
      previews: [],
      isPreviewLoading: false,
      isRestoring: false,
      isEditTextMode: false,
    };
  },
  computed: {
    ...mapState({
      taxes: (state) => state.taxes.list,
      users: (state) => state.users.list,
      venue: (state) => state.venues.selectedVenue,
    }),
    isEditing() {
      return this.$route.name === "EditMailingTemplate";
    },
    templateId() {
      return this.$route.params.id;
    },
    fromDisplayName() {
      return this.venue?.name || "YoReferee Booking";
    },
  },
  async created() {
    await this.fetchUsers();
    await this.fetchProducts();
  },
  methods: {
    sanitize,
    ...mapActions({
      deleteMailingTemplate: "mailingTemplates/deleteMailingTemplate",
      fetchUsers: "users/fetchUsers",
      fetchProducts: "products/fetchProducts",
    }),
    submit() {
      this.$emit("submit", this.form);
    },
    getUsersEmailByRole(roles) {
      return roles
        .map((role) =>
          this.users
            .filter((item) => item.role === role)
            .map((item) => {
              return {
                value: item.email,
                name: item.email,
              };
            })
        )
        .filter((item) => item)
        .flat();
    },
    async handleDelete() {
      const confirmed = await dialog.confirm({
        title: "Are you sure?",
        message: "All mailing template data will be lost",
        okText: "Delete",
        cancelText: "Cancel",
      });

      if (confirmed) {
        try {
          this.isDeleting = true;
          await this.deleteMailingTemplate(this.$route.params.id);
          await this.$router.push({ name: "MailingTemplates" });
        } finally {
          this.isDeleting = false;
        }
      }
    },
    async handleShowPreview() {
      try {
        const { subject, text } = this.form;
        this.isPreviewLoading = true;
        this.previews = await MailingTemplatesService.getPreview({
          subject,
          text,
        });
        this.$modal.show(this.previewModalName);
      } catch (err) {
        console.log("err > ", err);
      } finally {
        this.isPreviewLoading = false;
      }
    },
    async restore() {
      if (
        await dialog.confirm({
          title: "Are you sure?",
          message: "All mailing template data will be restored to default",
          okText: "Restore",
          cancelText: "Cancel",
        })
      ) {
        try {
          this.isRestoring = true;
          const restoredTemplate = await MailingTemplatesService.restore(
            this.$route.params.id
          );
          this.$store.commit(
            "mailingTemplates/SET_EDITED_ITEM",
            restoredTemplate
          );
          alert.open({
            message: "Template was successfully restored to default",
            variant: "success",
          });
        } catch (err) {
          console.log("Failed to restore mailin template: ", err);
        } finally {
          this.isRestoring = false;
        }
      }
    },
  },
  watch: {
    value(newFormValue) {
      this.form = {
        ...newFormValue,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.mailing-template-form {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__actions {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $media-laptop) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  &__text {
    margin-top: 42px;

    &-top {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 14px;

      &__right {
        display: flex;
        gap: 12px;
        align-items: center;
      }
    }

    &__preview {
      border: none;
      width: 100%;
      height: 50vh;
      overflow: auto;
    }
  }

  &__label {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    padding-bottom: 8px;
  }

  &__previewButton {
    padding: 10px 24px;
    border: 1px solid $secondary-300 !important;
    border-radius: 40px;
    color: $black !important;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    grid-gap: 8px;
    min-width: 142px;
    background-color: $white !important;
  }
}
</style>
