<template>
  <div class="editor-variables">
    <div
      v-for="(item, index) in options"
      :key="index"
      class="editor-variables__item"
      @click="$emit('select', item)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "EditorVariables",
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-variables {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: $secondary-200;
  padding: 10px 16px;
  overflow: auto;

  @media (min-width: $media-laptop) {
    overflow: hidden;
    flex-wrap: wrap;
  }

  &__item {
    padding: 5px 10px;
    border-radius: 50px;
    font-size: 10px;
    line-height: 14px;
    color: $secondary-500;
    cursor: pointer;
    background-color: $secondary-300;
    white-space: nowrap;
  }
}
</style>
