<template>
  <div class="mailing-template-editor">
    <EditorVariables :options="variables" @select="insertVariable" />
    <codemirror
      ref="editor"
      v-model="innerValue"
      :options="options"
      class="mailing-template-editor__editor"
    />
  </div>
</template>

<script>
import { codemirror } from "vue-codemirror";
import "codemirror/mode/handlebars/handlebars";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/lib/codemirror.css";
import EditorVariables from "@/components/common/EditorVariables.vue";
import { MailingTemplateVariableEnum } from "@/helpers/enums";
import { MAILING_TEMPLATE_VARIABLES_NAMES_MAP } from "@/helpers/const";
import { mapState } from "vuex";

export default {
  name: "MailingTemplateEditor",
  components: {
    EditorVariables,
    codemirror,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: {
        tabSize: 4,
        mode: { name: "handlebars", base: "htmlmixed" },
        lineNumbers: true,
        line: true,
        readOnly: this.isReadonly,
        orig: this.value,
      },
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.products.list,
    }),
    innerValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    variables() {
      return [
        ...Object.values(MailingTemplateVariableEnum).map((variableId) => ({
          value: variableId,
          name: MAILING_TEMPLATE_VARIABLES_NAMES_MAP[variableId],
        })),
        ...this.products.flatMap((product) => {
          return product.customFields
            ? Object.entries(product.customFields).map(([key, field]) => ({
                value: `CUSTOM_FIELD.${key}`,
                name: field.label,
              }))
            : [];
        }),
      ];
    },
  },
  methods: {
    insertVariable(variable) {
      this.$refs.editor?.codemirror?.replaceSelection?.(
        `{{${variable.value}}}`
      );
    },
  },
};
</script>

<style lang="scss">
.mailing-template-editor {
  .CodeMirror {
    height: 500px;
  }
  .CodeMirror-gutters {
    background: $white;
    border-color: $secondary-400;
  }
}
</style>

<style lang="scss" scoped>
.mailing-template-editor {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__editor {
    border-radius: 8px;
    border: 2px solid $secondary-400;
    background: $white;
    overflow: auto;
  }
}
</style>
